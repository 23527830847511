<template>
  <div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <v-col cols="10" sm="8" class="pa-0 mt-10 pt-10 mx-auto" v-else>
      <div class="font-bold text-2xl sm:text-4xl mt-5">
        {{ dataItem.name || "-" }}
      </div>
      <div class="text-sm py-2 pa-0">
        {{
          dataItem.start_date === dataItem.end_date
            ? dataItem.start_date
            : dataItem.start_date + " - " + dataItem.end_date
        }}
      </div>
      <img
        :src="baseUrlImage + dataItem.image.file_path"
        @error="$event.target.src = 'https://admin.bpdlh.id/assets/empty.png'"
        width="100%"
        class="rounded-md mt-8 img-detail" />
      <div class="font-bold text-base sm:text-xl my-5 text-left">
        {{ bahasa ? "Deskripsi" : "Description" }}
      </div>
      <div class="text-left mb-10" v-html="dataItem.description"></div>
    </v-col>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoad: false,
      dataItem: {},
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
    };
  },
  watch: {
    language() {
      this.getData();
    },
  },
  computed: {
    pageName() {
      let path = this.$route.name;
      let page = path.split("/").join("");
      return page.includes("-") ? page.split("-").join(" ") : page;
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
    descHeight() {
      return this.$refs.infoBox.clientHeight;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    goToProgram(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
    async getData() {
      this.isLoad = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/event/" + this.id, { params: param })
        .then((response) => {
          this.dataItem = response.data.data;
        })
        .catch((error) => {
          if (error.response.data.code === 404) {
            this.$router.push({ name: "NotFound" });
          } else {
            this.isLoad = false;
            Swal.fire("Failed!", error.response.data.message, "error");
          }
        })
        .finally(() => (this.isLoad = false));
    },
  },
};
</script>
